<template>
  <div>
    <headSearch v-if="!getClientInfo()" :search-out="headGo" />
    <div class="warp" :style="&quot;background:&quot;+activityInfo.backColor">
      <div class="banner">
        <img :src="activityInfo.backGround.url" alt>
      </div>
      <van-sticky>
        <div v-if="typeList!=''" class="menuList" :style="&quot;background:&quot;+activityInfo.tabColor">
          <!-- <ul>
            <li
              v-for="item in typeList"
              :key="item.id"
              :class="{active:num==item.id}"
              @click="getNum(item.id)"
            >{{item.name}}</li>
          </ul> -->
          <van-tabs background="rgb(32, 28, 24)" color="#ffffff" title-active-color="#ffffff" @click="getNum">
            <van-tab v-for="item in typeList" :key="item.name" :title="item.name" />
          </van-tabs>
        </div>
      </van-sticky>
      <div class="tabCon_header">
        <img :src="teamBanner" alt>
      </div>
      <div class="tabCon">
        <div
          v-for="(item,index) in goodsInfo"
          :key="index"
          class="tabCon-item"
          @click="goShopdetail(item)"
        >
          <img :src="item.goodCover" alt>
        </div>
      </div>
      <!--暂时取消-->
      <!-- <div class="take_footer">
        <div class="take_footer-title">规则说明</div>
        <div class="take_footer-txt">1.新用户注册白拿一次</div>
        <div class="take_footer-txt">2.分享好友购买,每三个好友购买,自己可多一次白拿次数</div>
      </div> -->
    </div>
  </div>
</template>
<script>
import headSearch from '@/component/head/search'
import Vue from 'vue'
import { Sticky, Tab, Tabs, Toast } from 'vant'

Vue.use(Toast).use(Sticky).use(Tab).use(Tabs)
import {
  getActivityCategoryInfo,
  newActivityList
} from '@/services/activity'
// 方法引入
import { getClientInfo } from '@/utils/public.js'

export default {
  name: 'Takewithoutpaying',
  components: {
    headSearch
  },
  data() {
    return {
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: document.title,
        callbackGo: this.callbackGo
      },
      num: '75', // 默认id
      activityInfo: '', // 分类集合
      typeList: [], // 类型列表
      teamBanner: '', // 活动提示图
      goodsInfo: '' // 白拿列表
    }
  },
  mounted() {
    this.getCategoryInfo()
    this.getList()
  },
  methods: {
    // 判断当前是否为移动端
    getClientInfo() {
      return getClientInfo()
    },
    callbackGo() {
      this.$router.go(-1)
    },
    // 选项卡
    getNum(e) {
      window.scrollTo(0, 0)
      this.num = this.typeList[e].id
      this.getList()
    },
    // 获取白拿类型
    getCategoryInfo() {
      const _self = this
      const params = {
        id: this.$route.query.id || 55
      }
      getActivityCategoryInfo(params).then((res) => {
        if (Number(res.code) === 200) {
          _self.activityInfo = res.data.activityInfo
          _self.typeList = res.data.categoryInfo
        } else {
          Toast(res.msg)
        }
      })
    },
    // 获取白拿商品列表
    getList() {
      const _self = this
      const params = {
        id: this.$route.query.id || 55,
        categoryId: _self.num,
        page: 1,
        pageSize: 10
      }
      newActivityList(params).then((res) => {
        if (Number(res.code) === 200) {
          _self.goodsInfo = res.data[0].goodsInfo
          _self.teamBanner = res.data[0].teamBanner
        } else {
          Toast(res.msg)
        }
      })
    },
    // 去详情
    goShopdetail(e) {
      const obj = {
        product_type: 1,
        goodId: e.goodId,
        skuId: e.productSkuId,
        activityId: e.activityId
      }
      this.$store.dispatch('godetail', obj)

      // this.$router.push({path:'/goodsDetailZY',query:{goodsId:e.goodId,skuid:e.productSkuId}})
    }

  }
}
</script>
<style lang="less" scoped>
.warp {
  width: 100%;
  height: auto;

  .banner {
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .menuList {
    width: 100%;
    height: auto;
    overflow: auto;

    ul {
      width: 100%;
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      color: #e8e8e8;
      font-size: 0.4rem;
      line-height: 1rem;

      li {
        flex: 1;
        text-align: center;
      }
    }

    .active {
      border-bottom: 1px solid #ffffff;
    }
  }

  .tabCon_header img {
    width: 9.4rem;
    height: 5rem;
    margin: 0.3rem auto;
    display: block;
    font-size: 0;
    vertical-align: middle;
  }

  .tabCon {
    width: 100%;
    height: auto;
    overflow: auto;
    color: #999;
    font-size: 0.3rem;

    .tabCon-item {
      width: 100%;
      height: auto;
      overflow: auto;
      margin: 0.3rem auto;
    }

    .tabCon-item img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .take_footer {
    color: #ffffff;
    padding: 0.8rem;
    text-align: left;

    .take_footer-title {
      font-size: 0.4rem;
    }

    .take_footer-txt {
      font-size: 0.3rem;
      line-height: 0.5rem;
    }
  }
}
</style>
